import React, { useState } from 'react'
import logo from './logo.png'
import s from './App.module.css'

function App () {
  const [ tab, setTab ] = useState<'upload' | 'gallery'>('upload')

  return (
    <div className={s.app}>
      <header className={s.header}>
        <img src={logo} className={s.logo} alt="logo" />
        <nav className={s.nav}>
          <button
            onClick={() => setTab('upload')}
            className={`${s.navButton} ${tab === 'upload'
              ? s.navButtonActive
              : ''}`}
            type="button"
          >Upload
          </button>
          <button
            onClick={() => setTab('gallery')}
            className={`${s.navButton} ${tab === 'gallery'
              ? s.navButtonActive
              : ''}`}
            type="button"
          >Gallery
          </button>
        </nav>
      </header>
      <div className={s.content}>
        {tab === 'upload' && <iframe
          title="form"
          className="airtable-embed airtable-dynamic-height"
          src="https://airtable.com/embed/shrvD5u8XaIW9BCiR?backgroundColor=blue"
          frameBorder="0"
          width="100%"
          height="793"
          style={{ background: 'transparent' }}
        />}
        {tab === 'gallery' && <div className={s.galleryContainer}>
          <iframe
            title="gallery"
            className={`airtable-embed airtable-dynamic-height ${s.gallery}`}
            src="https://airtable.com/embed/shrpB7KYlXbBTtWQz?backgroundColor=blue"
            frameBorder="0"
            width="100%"
            height="100%"
            style={{ background: 'transparent' }}
          ></iframe>
        </div>}
      </div>
    </div>
  )
}

export default App
